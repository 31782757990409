import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { device } from "../utils/mixins"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  PageTopFullSizeImgCont,
  SectionContentContainer,
} from "../components/imageComponents"
import {
  PageSectionTitle,
  I2BronzeText,
  BodyText,
  BodyLink,
  BodyBold,
} from "../components/textComponents"

var Image = styled(Img)`
  max-height: 15rem;
  position: absolute;

  ${device.mobileX} {
    max-height: 20rem;
  }

  ${device.tablet} {
    max-height: 23rem;
  }
`

function CaseStudiesConfirmation() {
  var data = useStaticQuery(graphql`
    query {
      caseStudiesConfirmTopImg: file(
        relativePath: { eq: "i2OBiFuelSubstitutionDieselDisplacement.jpeg" }
      ) {
        ...fluidImageSVG
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Case Studies Sign Up Confirmed"
        description="You're subscribed to i2O Insights, the place to learn about the latest and greatest tech in the Oil & Gas Industry."
      />
      <PageTopFullSizeImgCont>
        <Image
          style={{ height: "inherit" }}
          objectFit="cover"
          fluid={data.caseStudiesConfirmTopImg.sharp.fluid}
          alt="i2 Optimization - Bi Fuel Substitution - Diesel Displacement Optimization"
        />
        <PageSectionTitle>
          Sign Up <I2BronzeText>Confirmed</I2BronzeText>
        </PageSectionTitle>
      </PageTopFullSizeImgCont>
      <SectionContentContainer>
        <BodyText>
          We've successfully added you to our quarterly i2O Insights newsletter!
          You can unsubscribe at any time by contacting us. Not that you'd ever
          want to.
        </BodyText>

        <BodyText>Feel free to check out the rest of our site:</BodyText>

        <BodyText>
          <BodyBold>
            To call, email, or send us a message, check out the{" "}
            <BodyLink to="/contact-us/">Contact Us page.</BodyLink>
          </BodyBold>
        </BodyText>
        <BodyText>
          <BodyBold>
            To get a little bit more insight into our background, visit our{" "}
            <BodyLink to="/industry-experience/">
              Industry Experience page.
            </BodyLink>
          </BodyBold>
        </BodyText>
        <BodyText>
          <BodyBold>
            Have a question about our process, or optimization in general? Check
            out the <BodyLink to="/faqs/">FAQs page.</BodyLink>
          </BodyBold>
        </BodyText>
      </SectionContentContainer>
    </Layout>
  )
}

export default CaseStudiesConfirmation
